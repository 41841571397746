import React from "react"
import { switchbackResolveRichText } from "@utils/resolve-rich-text/switchbackResolveRichText"
import OptimizedImage from "../../../utils/optimizedImage"
import { Button } from "../../../atoms"
import {
  FeaturedItem,
  FeaturedItemWrapper,
  ItemCopyContainer,
  ItemHeading,
  ItemDescription,
  ItemImageContainer,
} from "../styles/CarouselContentBlock.styled"

const ContentBlockItem = ({ item }) => {
  const { heading, subheading, callsToAction, featuredImage } = item
  const isGif = featuredImage?.file?.url.includes(".gif")

  return (
    <div>
      <FeaturedItem>
        <FeaturedItemWrapper>
          <ItemCopyContainer>
            <ItemHeading>{heading}</ItemHeading>
            {subheading && (
              <ItemDescription>
                {switchbackResolveRichText(subheading)}
              </ItemDescription>
            )}
            {callsToAction && callsToAction && (
              <Button
                style={{ marginTop: "30px" }}
                ctaVariant={callsToAction[0]?.variant || "primary"}
                ctaLink={callsToAction[0]?.link}
                {...callsToAction[0]}
              >
                {callsToAction[0]?.label}
              </Button>
            )}
          </ItemCopyContainer>
          <ItemImageContainer isGif={isGif}>
            <OptimizedImage
              image={
                featuredImage?.gatsbyImageData && !isGif
                  ? featuredImage.gatsbyImageData
                  : undefined
              }
              src={
                featuredImage?.file?.url || "/images/home-improve-feature-3.png"
              }
              alt={ featuredImage?.description || featuredImage?.title || "Featured Image"}
            />
          </ItemImageContainer>
        </FeaturedItemWrapper>
      </FeaturedItem>
    </div>
  )
}

export default ContentBlockItem
